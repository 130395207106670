<template>
    <TheMain>
        <template #main>
            <BaseHeader :title="'Default'" />
            <BodyContainerCenter>
                <template #left>
                    <LayoutSidebarLeft />
                </template>
                <template #center>
                    <slot />
                </template>
                <template #right>
                    <LayoutSidebarRight />
                </template>
            </BodyContainerCenter>
            <BaseFooter />
            <BaseErrorMaintenanceMode v-if="false" />
        </template>
    </TheMain>
</template>

<script setup lang="ts">
import BaseErrorMaintenanceMode from '@/components/Base/Error/BaseErrorMaintenanceMode.vue';
import BaseFooter from '@/components/Base/Footer/BaseFooter.vue';
import BaseHeader from '@/components/Base/Header/BaseHeader.vue';
import BodyContainerCenter from '@/components/Body/BodyContainerCenter.vue';
import LayoutSidebarLeft from '@/components/Layout/Sidebar/LayoutSidebarLeft.vue';
import LayoutSidebarRight from '@/components/Layout/Sidebar/LayoutSidebarRight.vue';
import TheMain from '@/components/TheMain.vue';
import { useStoreSystem } from '@/stores/system';

const appSystem = useStoreSystem();

</script>
